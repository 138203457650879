<template>
  <v-row>
    <template v-if="applications.length >= 1">
      <v-col
        v-for="application in displayedApplicationsList"
        :key="application.title"
        cols="12"
        md="3"
      >
        <div
          :class="
            ![
              ApplicationStatus.ENDED,
              ApplicationStatus.PUBLISHED,
              ApplicationStatus.CANCELLED,
              ApplicationStatus.EXPIRED,
              ApplicationStatus.CORRECTED,
              ApplicationStatus.WAITING
            ].includes(application.status)
              ? `bg-Azul-95 rounded-xl`
              : `bg-transparent !border-2 !border-[#A4CFF4] rounded-xl`
          "
        >
          <v-card-item class="!pt-5 pb-4 px-6">
            <v-card-title>
              <span
                class="text-sm font-rubik font-medium leading-[24px] tracking-[0.15px] text-[#333] font-rubik"
              >
                {{ application.name }}
              </span>
            </v-card-title>
            <v-card-actions class="!p-0">
              <div class="flex space-x-2 w-full">
                <ApplicationButtonAction :application="application" />
                <div v-if="application!.reinforce_counter">
                  <v-chip class="mr-[8px]" color="primary" rounded>
                    <span>{{ application!.reinforce_counter }}</span>
                  </v-chip>
                </div>
              </div>
            </v-card-actions>
          </v-card-item>
        </div>
      </v-col>
      <v-col v-if="is_not_started && applications.length > 3">
        <template v-if="hasMoreApplications">
          <BaseButtonLoudMore
            :icon="LoadMoreStatusEnum.MORE"
            customText="Ver mais avaliações em aberto"
            @click="loadMoreApplications"
          />
        </template>
        <template v-else>
          <BaseButtonLoudMore
            :icon="LoadMoreStatusEnum.NO_MORE"
            customText="Ver menos"
            fill="#fff"
            @click="loadLessApplications"
          />
        </template>
      </v-col>
    </template>
  </v-row>
</template>
<script lang="ts" setup>
import type { Application } from '@/models/application.model'
import { computed, provide, ref, toRefs, watch } from 'vue'
import { ApplicationStatus } from '@/enums/application-status.enum'
import { useRouter } from 'vue-router'
import ApplicationButtonAction from './ApplicationButtonAction.vue'
import { useDisplay } from 'vuetify'
import BaseButtonLoudMore from '@/components/base/BaseButtonLoudMore.vue'
import { LoadMoreStatusEnum } from '@/enums/load-more-status.enum'

const router = useRouter()

const props: {
  applications?: Application[]
  is_not_started?: Boolean
} = defineProps(['applications', 'is_not_started'])

const { applications } = toRefs(props)

const { mobile } = useDisplay()

const displayedApplications = ref(3)
const hasMoreApplications = ref(true)

const displayedApplicationsList = computed(() => {
  if (props.is_not_started) {
    return applications!.value!.slice(0, displayedApplications.value)
  } else {
    return applications!.value
  }
})

const loadMoreApplications = () => {
  if (displayedApplications.value + 4 < applications!.value!.length) {
    displayedApplications.value += 4
  } else {
    displayedApplications.value = applications!.value!.length
    hasMoreApplications.value = false
  }
}

const loadLessApplications = () => {
  displayedApplications.value = 3
  hasMoreApplications!.value = true
}

watch(displayedApplications, (newValue) => {
  if (newValue >= applications!.value!.length!) {
    hasMoreApplications.value = false
  } else {
    hasMoreApplications.value = true
  }
})

const emit = defineEmits(['start', 'resume', 'paginate'])

const start = (application: Application) => {
  emit('start', application)
}
provide('start', start)

const resume = (application: Application) => {
  emit('resume', application)
}
provide('resume', resume)

const goToFeedback = (applicationId: string) => {
  router.push(`/feedback/${applicationId}`)
}
provide('goToFeedback', goToFeedback)
</script>

<style scoped>
.v-container {
  padding-right: 0;
  padding-left: 0;
}
</style>
