<template>
  <div
    :class="{
      'mx-10': !mobile,
      'mx-2': mobile
    }"
  >
  <div id="mainToolbarParent">
    <div class="flex justify-between bg-[#fff] h-[5rem] rounded-b-xl px-5 py-5 z-40 mb-5" id="mainToolbar">
      <div class="flex justify-between items-center">
          <EnemSimuladoIcon />
      </div>
      <div class="flex items-center justify-center">
        <v-btn class="!border-2 text-none" color="blue" variant="outlined" @click="btnAction">
          <span :style="fontSm">Voltar</span>
        </v-btn>
      </div>
    </div>
  </div>

    <v-row no-gutters>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-sheet class="py-[20px] px-[24px] md:mr-[20px] rounded-xl shadow-sheet">
              <div class="flex justify-between space-x-2">
                <div>
                  <span :style="fontXl" class="font-medium text-sm line-clamp-2">
                    {{ data.application?.name }}
                  </span>
                </div>
                <div class="bg-[#1C86E3] text-white py-2 px-3 rounded-lg text-right text-xs">
                  <div>Nota</div>
                  <div class="font-medium text-base">
                    {{ data.userApplication?.correction?.final_grade
                    }}<span class="text-xs opacity-70">/{{ maxGrade }}</span>
                  </div>
                </div>
              </div>
            </v-sheet>
          </v-col>
          <template v-for="subFormQuestions of data.questions" :key="subFormQuestions.subFormOrder">
            <v-col
              v-for="(question, index) of (collect(subFormQuestions.questions) as any)
        .sortBy('order')
        .all()"
              :id="question.id"
              :key="question.id"
              cols="12"
            >
              <v-sheet class="py-[20px] px-[24px] md:mr-[20px] rounded-xl shadow-sheet">
                <v-main>
                  <div class="flex justify-between">
                    <div class="flex items-center">
                      <div class="mr-[12px]">
                        <v-chip
                          :style="fontSm"
                          class="!font-medium"
                          color="blue"
                          rounded
                          variant="elevated"
                          >{{ index + 1 }}
                        </v-chip>
                      </div>
                      <div class="mr-[12px]">
                        <v-chip
                          :style="fontSm"
                          class="!font-medium"
                          color="blue"
                          rounded
                          variant="elevated"
                          >{{ getQuestionType(question.type) }}
                        </v-chip>
                      </div>
                      <div>
                        <v-tooltip :text="getQuestionDescription(question.type)" open-on-click>
                          <template v-slot:activator="{ props }">
                            <v-chip class="!font-medium" color="blue" rounded v-bind="props">
                              <span :style="fontSm">?</span>
                            </v-chip>
                          </template>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="bg-[#1C86E3] text-white py-2 px-3 rounded-lg text-sm">
                      <div class="text-right text-xs">Nota</div>
                      <div class="text-right font-medium text-base">
                        {{ getQuestionFinalGrade(question)
                        }}<span class="tespanxt-xs opacity-70">/{{ question.grade }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="fontBase"
                    class="mt-[12px] mb-[20px]"
                    v-html="question.main_text"
                  ></div>
                  <template v-if="question.type === QuestionType.OBJECTIVE">
                    <ObjectiveAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.MATRIX">
                    <MatrixAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.TRUE_FALSE">
                    <TrueFalseAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.OPEN_ENDED">
                    <OpenEndedAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.MULTIPLE_CHOICE">
                    <MultipleChoiceAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.SORTING">
                    <SortingAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.FILL_IN_THE_BLANK">
                    <FillInTheBlankAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.CONNECT_THE_DOTS">
                    <ConnectTheDotsAlternatives :question="question" />
                  </template>

                  <template v-if="question.type === QuestionType.ESSAY">
                    <EssayAlternatives :question="question" />
                  </template>

                  <div v-if="question.feedback" class="relative mt-8">
                    <div
                      :class="{
                        'absolute left-6 -top-[12px] px-1 bg-white': !mobile,
                        'relative px-1 bg-white': mobile
                      }"
                    >
                      <span class="text-[#808080] text-xs font-normal not-italic">Correção</span>
                    </div>
                    <div
                      class="flex flex-col px-[10px] py-[10px] border-[#E8F3FC] items-start self-stretch rounded-[20px] border-2"
                      v-html="question.feedback"
                    ></div>
                  </div>
                </v-main>
              </v-sheet>
            </v-col>
          </template>
          <div class="pt-[20px] pb-[60px] w-full flex justify-end items-center mr-[28px]">
            <v-btn
              class="!border-2 text-none bg-white"
              color="blue"
              variant="outlined"
              @click="btnAction"
            >
              <span :style="fontSm">Sair</span>
            </v-btn>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts" setup>
import ConnectTheDotsAlternatives from '@/components/feedback/alternatives/ConnectTheDotsAlternatives.vue'
import FillInTheBlankAlternatives from '@/components/feedback/alternatives/FillInTheBlankAlternatives.vue'
import MatrixAlternatives from '@/components/feedback/alternatives/MatrixAlternatives.vue'
import MultipleChoiceAlternatives from '@/components/feedback/alternatives/MultipleChoiceAlternatives.vue'
import ObjectiveAlternatives from '@/components/feedback/alternatives/ObjectiveAlternatives.vue'
import OpenEndedAlternatives from '@/components/feedback/alternatives/OpenEndedAlternatives.vue'
import SortingAlternatives from '@/components/feedback/alternatives/SortingAlternatives.vue'
import TrueFalseAlternatives from '@/components/feedback/alternatives/TrueFalseAlternatives.vue'
import StringUtil from '@/utils/string.util'
import { QuestionType } from '@/enums/question-type.enum'
import {
  calculateFormQuestionsGrade,
  getApplicationQuestions,
  getFormQuestionsFromOrganization
} from '@/services/application.service'
import { useFontZoomStore } from '@/stores/font'
import collect from 'collect.js'
import { computed, onMounted, onUnmounted, provide, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useAuth } from '@/composables/useAuth'
import EssayAlternatives from '@/components/feedback/alternatives/EssayAlternatives.vue'
import CorrectQuestionIcon from '@/components/icons/CorrectQuestionIcon.vue'
import PartialCorrectIcon from '@/components/icons/PartialCorrectIcon.vue'
import WrongQuestionIcon from '@/components/icons/WrongQuestionIcon.vue'
import { getQuestionDescription, getQuestionType } from '@/services/question.service'
import EnemSimuladoIcon from '@/components/icons/EnemSimuladoIcon.vue'

const { mobile } = useDisplay()
const { user } = useAuth()
const fontZoomStore = useFontZoomStore()
const route = useRoute()
const router = useRouter()

const data = ref({} as any)
const formQuestions = ref([] as any)
const maxGrade = ref(0)
const activeQuestion = ref<string>('')

const fontSm = computed(() => useFontZoomStore().getFontSm)
const fontBase = computed(() => useFontZoomStore().getFontBase)
const fontLg = computed(() => useFontZoomStore().getFontLg)
const fontXl = computed(() => useFontZoomStore().getFontXl)
const fontSize = computed(() => fontZoomStore.getFontZoom)

onMounted(async () => {
  data.value = await getApplicationQuestions(route.params.applicationId as string, true)
  formQuestions.value = await getFormQuestionsFromOrganization(data.value?.application?.form_id)

  if (!data.value) {
    await router.push({ name: 'dashboard' })
  }

  maxGrade.value = await calculateFormQuestionsGrade(data.value?.application?.form_ref)

  document.querySelectorAll('iframe').forEach((iframe) => {
    iframe.setAttribute('width', (window!.innerWidth * 0.45).toString())
  })
})

const btnAction = () => {
  router.push({ name: 'dashboard' })
}

provide('formQuestions', formQuestions)

const getQuestionFinalGrade = (question: any) =>
  question?.final_grade ?? question?.partial_grade ?? 0

const handleScroll = (
  elementId: string,
  parentElementId: string,
  offset: number,
  additionalClass: string = ''
) => {
  const element = document.getElementById(elementId) as HTMLElement
  const parentElement = document.getElementById(parentElementId) as HTMLElement

  if (!element) return

  if (window.scrollY > offset) {
    element.classList.add('position-fixed')
    if (additionalClass) element.classList.add(additionalClass)
    element.style.width = `${parentElement.offsetWidth}px`
    return
  }

  element.classList.remove('position-fixed')
  if (additionalClass) element.classList.remove(additionalClass)
  element.style.width = ''
}

window.addEventListener('scroll', () => handleScroll('mainToolbar', 'mainToolbarParent', 60))
</script>
