<template>
  <div class="h-[5rem] bg-[#fff] rounded-b-xl mb-5 p-5 px-2">
    <div class="flex items-center justify-around md:justify-between md:px-6">
      <div class="flex items-center justify-center">
        <EnemSimuladoIcon />
      </div>
      <div class="flex items-center justify-around space-x-2">
        <span class="font-medium text-sm line-clamp-1">{{ user?.name }}</span>
        <v-avatar id="menu-avatar-activator" size="32">
          <img
            v-if="!user?.avatar"
            id="menu-avatar-activator"
            alt="Imagem de perfil"
            height="48"
            src="/assets/default_user.png"
            width="48"
          />
          <img
            v-else
            id="menu-avatar-activator"
            :src="user?.avatar"
            alt="Imagem de perfil"
            height="48"
            width="48"
          />
        </v-avatar>
      </div>
    </div>
    <v-menu activator="#menu-avatar-activator">
      <ul
        class="flex flex-col font-rubik font-medium bg-Azul-99 rounded-xl border-2 !border-Azul-50"
      >
        <li
          v-for="(menu, index) in menuItems"
          :key="menu.name"
          :class="[
            'cursor-pointer hover:bg-Azul-95 transition duration-500 border-b p-[0.5rem] border-1 border-Azul-50',
            { 'rounded-t-xl': index === 0, 'rounded-b-xl': index === menuItems.length - 1 }
          ]"
          @click="handleChangeAction(menu)"
        >
          <div class="flex py-2 px-3 space-x-1">
            <v-icon :class="menu.color">{{ menu.mdiIcon }}</v-icon>
            <span
              :class="[
                menu.name === MenuEnum.LOGOUT
                  ? 'text-Vermelho-50'
                  : 'text-B&W-30 hover:text-Azul-50'
              ]"
            >
              {{ menu.name }}
            </span>
          </div>
        </li>
      </ul>
    </v-menu>
  </div>
</template>

<script lang="ts" setup>
import EnemSimuladoIcon from '@/components/icons/EnemSimuladoIcon.vue'
import type { Organization } from '@/models/organization.model'
import { onMounted, ref } from 'vue'
import { getActiveOrganizationData } from '@/services/organization.service'
import { logout } from '@/services/auth.service'
import { useRouter } from 'vue-router'
import { getProviderById } from '@/services/provider.service'
import { useLoadingStore } from '@/stores/loading'
import { useDisplay } from 'vuetify'
import { useAuth } from '@/composables/useAuth'
import { useImpersonate } from '@/composables/useImpersonate'
import MenuEnum from '@/enums/menu.header.enum'
import type { IMenuItems } from '@/interfaces/menu-items.interface'

const router = useRouter()
const loadingStore = useLoadingStore()
const { user } = useAuth()
const { mobile } = useDisplay()
const { isImpersonating } = useImpersonate()

const organization = ref({} as Organization)
const providerLogo = ref<string>('')
const menuItems = ref<IMenuItems[]>([
  {
    name: MenuEnum.SWITCH_ORGANIZATION,
    route: '/organizations',
    mdiIcon: 'mdi-rotate-3d-variant',
    color: 'text-blue'
  },
  { name: MenuEnum.LOGOUT, action: 'logout', mdiIcon: 'mdi-logout', color: 'text-red' }
])

onMounted(async () => {
  organization.value = await getActiveOrganizationData()
  await getProviderLogo()
})

const handleChangeAction = (item: IMenuItems) => {
  if (item.route) {
    router.push(item.route)
  } else if (item.action === 'logout') {
    handleLogout()
  }
}

const handleLogout = async () => {
  try {
    loadingStore.startLoading()
    await logout()
  } finally {
    loadingStore.stopLoading()
  }
}

const getProviderLogo = async () => {
  const providerData = await getProviderById(organization?.value?.provider_id)
  providerLogo.value = providerData?.logo
}


</script>

<style>
.v-list {
  border: 1px solid #1c86e3;
}

.v-list-item {
  border-bottom: 1px solid #1c86e3;
}

.v-list-item:last-child {
  border-bottom: 0;
}
</style>
