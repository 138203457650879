<template>
  <button
    v-if="
      application?.status === ApplicationStatus.NOT_STARTED.toString() ||
      userApplication?.status === ApplicationStatus.NOT_STARTED.toString()
    "
    :class="[`bg-Azul-50 w-full rounded-xl text-[#fff] p-1`]"
    @click="start(application)"
  >
    {{ application?.is_google_forms ? 'Abrir Google Formulário' : 'Iniciar' }}
  </button>
  <button
    v-else-if="application?.status === ApplicationStatus.IN_PROGRESS.toString()"
    :class="[`bg-Azul-50 w-full rounded-xl text-[#fff] p-1`]"
    class="bg-Azul-50 w-full rounded-xl !border-2 text-[#fff] p-1"
    @click="resume(application)"
  >
    Retomar
  </button>
  <button
    v-else-if="userApplication?.status === ApplicationStatus.PUBLISHED.toString()"
    :class="[`bg-Azul-50  w-full rounded-xl !border-2 !border-indigo-600 text-[#fff] p-1`]"
    :disabled="userApplication?.status !== ApplicationStatus.PUBLISHED.toString()"
    @click="goToFeedback(application.id)"
  >
    Gabarito
  </button>
</template>

<script lang="ts" setup>
import { db } from '@/config/firebase'
import { ApplicationStatus } from '@/enums/application-status.enum'
import type { Application } from '@/models/application.model'
import { doc } from 'firebase/firestore'
import { computed, inject, type PropType } from 'vue'
import { useDocument } from 'vuefire'

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true
  }
})

const start = inject('start') as Function
const resume = inject('resume') as Function
const goToFeedback = inject('goToFeedback') as Function

const userApplicationSource = computed(() => {
  if (!props.application?.id || !props.application?.user_id) return
  return doc(db, 'users', props.application.user_id, 'applications', props.application.id)
})

const userApplication = useDocument(userApplicationSource)
</script>
