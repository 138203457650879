<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="93" height="43" viewBox="0 0 93 43" fill="none">
    <path d="M10.34 42.5966C4.312 42.5966 0 37.8886 0 31.5086V30.4086C0 24.1606 4.444 19.3646 10.34 19.3646C16.412 19.3646 20.68 23.9406 20.68 30.4086V31.5526H3.652V31.9046C3.652 36.3486 6.424 39.6926 10.34 39.6926C13.2 39.6926 15.576 37.8886 16.588 34.8526H20.24C19.096 39.6046 15.312 42.5966 10.34 42.5966ZM3.696 28.9126H17.028C16.5 24.9966 13.772 22.2686 10.384 22.2686C6.952 22.2686 4.224 24.9526 3.696 28.9126Z" fill="#1C86E3"/>
    <path d="M24.6531 41.9806H21.0451V19.9806H24.6531V21.0366C24.6531 21.9166 24.5211 22.6206 24.1691 23.5446L23.9931 24.0286L24.0811 24.1166L24.6531 23.2366C26.1051 20.9046 28.6571 19.3646 31.5611 19.3646C36.5771 19.3646 39.4371 22.6646 39.4371 28.4286V41.9806H35.8291V28.3846C35.8291 24.4686 34.0691 22.2686 30.7251 22.2686C27.5131 22.2686 24.6531 24.9086 24.6531 28.9566V41.9806Z" fill="#1C86E3"/>
    <path d="M50.1789 42.5966C44.1509 42.5966 39.8389 37.8886 39.8389 31.5086V30.4086C39.8389 24.1606 44.2829 19.3646 50.1789 19.3646C56.2509 19.3646 60.5189 23.9406 60.5189 30.4086V31.5526H43.4909V31.9046C43.4909 36.3486 46.2629 39.6926 50.1789 39.6926C53.0389 39.6926 55.4149 37.8886 56.4269 34.8526H60.0789C58.9349 39.6046 55.1509 42.5966 50.1789 42.5966ZM43.5349 28.9126H56.8669C56.3389 24.9966 53.6109 22.2686 50.2229 22.2686C46.7909 22.2686 44.0629 24.9526 43.5349 28.9126Z" fill="#1C86E3"/>
    <path d="M64.492 41.9806H60.884V19.8486H64.492V20.8166C64.492 21.6526 64.36 22.3566 64.008 23.2806L63.832 23.7646L63.92 23.8526L64.492 22.9726C65.9 20.6846 68.452 19.3646 71.048 19.3646C74.304 19.3646 76.68 20.8606 77.868 23.4566H78.044C79.408 21.0806 82.092 19.3646 85.304 19.3646C90.144 19.3646 93.004 22.6206 93.004 28.1646V41.9806H89.396V28.1646C89.396 24.6006 87.68 22.2686 84.512 22.2686C81.564 22.2686 78.748 24.7766 78.748 28.6046V41.9806H75.14V28.1646C75.14 24.6006 73.468 22.2686 70.256 22.2686C67.352 22.2686 64.492 24.7766 64.492 28.6046V41.9806Z" fill="#1C86E3"/>
    <path d="M6.27306 15.015C3.81606 15.015 2.13606 13.965 1.90506 12.243H3.77406C4.04706 13.125 4.97106 13.65 6.27306 13.65C7.78506 13.65 8.87706 12.936 8.87706 11.928C8.87706 11.13 8.33106 10.647 6.73506 10.311L5.03406 9.933C3.06006 9.513 2.11506 8.568 2.11506 7.056C2.11506 5.145 3.75306 3.927 6.27306 3.927C8.60406 3.927 10.1581 4.956 10.3261 6.657H8.58306C8.35206 5.817 7.49106 5.292 6.27306 5.292C4.97106 5.292 3.85806 5.838 3.85806 6.972C3.85806 7.896 4.48806 8.232 5.95806 8.568L7.59606 8.904C9.61206 9.345 10.6621 10.353 10.6621 11.802C10.6621 13.776 8.94006 15.015 6.27306 15.015Z" fill="#1C86E3"/>
    <path d="M14.2854 1.974H12.5634V0H14.2854V1.974ZM14.2854 14.7H12.5634V4.2H14.2854V14.7Z" fill="#1C86E3"/>
    <path d="M18.6125 14.721H16.8905V4.158H18.6125V4.62C18.6125 5.019 18.5495 5.355 18.3815 5.796L18.2975 6.027L18.3395 6.069L18.6125 5.649C19.2845 4.557 20.5025 3.927 21.7415 3.927C23.2955 3.927 24.4295 4.641 24.9965 5.88H25.0805C25.7315 4.746 27.0125 3.927 28.5455 3.927C30.8555 3.927 32.2205 5.481 32.2205 8.127V14.721H30.4985V8.127C30.4985 6.426 29.6795 5.313 28.1675 5.313C26.7605 5.313 25.4165 6.51 25.4165 8.337V14.721H23.6945V8.127C23.6945 6.426 22.8965 5.313 21.3635 5.313C19.9775 5.313 18.6125 6.51 18.6125 8.337V14.721Z" fill="#1C86E3"/>
    <path d="M38.4498 15.015C36.0558 15.015 34.6908 13.44 34.6908 10.689V4.221H36.4128V10.71C36.4128 12.579 37.2318 13.629 38.7648 13.629C40.2978 13.629 41.7258 12.369 41.7258 10.437V4.221H43.4688V14.721H41.7258V14.175C41.7468 13.776 41.8098 13.44 41.9778 13.02L42.0408 12.81L41.9988 12.747L41.7258 13.167C41.0538 14.28 39.8358 15.015 38.4498 15.015Z" fill="#1C86E3"/>
    <path d="M47.7951 14.721H46.0731V0.0209997H47.7951V14.721Z" fill="#1C86E3"/>
    <path d="M53.0742 15.015C50.9952 15.015 49.5462 13.797 49.5462 12.075C49.5462 10.101 50.8692 9.114 52.9482 8.757L56.0562 8.232C56.4972 8.169 56.7282 7.938 56.7282 7.623V7.455C56.7282 6.3 55.7202 5.439 54.2922 5.439C53.1162 5.439 52.0452 5.964 51.7092 7.077H49.9452C50.3022 5.313 51.9192 3.927 54.3342 3.927C56.7912 3.927 58.5132 5.292 58.5132 7.371V14.721H56.8542V14.175C56.8542 13.734 56.8962 13.377 57.0852 12.957L57.2112 12.642L57.1902 12.621L56.8542 13.167C56.0562 14.427 54.5232 15.015 53.0742 15.015ZM53.4522 13.608C55.1112 13.608 56.7282 12.579 56.7282 11.151V9.408L53.3892 9.975C51.9822 10.227 51.2682 10.794 51.2682 11.97C51.2682 12.936 52.1922 13.608 53.4522 13.608Z" fill="#1C86E3"/>
    <path d="M64.5577 15.015C61.9747 15.015 60.1477 12.747 60.1477 9.702V9.24C60.1477 6.09 61.9537 3.906 64.5577 3.906C65.9647 3.906 67.2877 4.641 67.9177 6.111H67.9807V0.0209997H69.7237V14.7H67.9807V13.776C67.9807 13.23 68.0857 12.81 68.2117 12.306L68.2747 12.117L68.2327 12.096L67.9807 12.642C67.3507 14.154 65.9857 15.015 64.5577 15.015ZM64.8307 13.629C66.5947 13.629 67.9387 12.138 67.9387 10.038V8.862C67.9387 6.741 66.6367 5.292 64.8727 5.292C63.2767 5.292 61.8697 6.825 61.8697 9.198V9.702C61.8697 12.075 63.2767 13.629 64.8307 13.629Z" fill="#1C86E3"/>
    <path d="M76.6076 15.015C73.7516 15.015 71.6936 12.768 71.6936 9.702V9.219C71.6936 6.237 73.8146 3.927 76.6076 3.927C79.4846 3.927 81.5426 6.132 81.5426 9.219V9.702C81.5426 12.81 79.5266 15.015 76.6076 15.015ZM76.6076 13.629C78.4976 13.629 79.7996 11.97 79.7996 9.849V9.093C79.7996 6.993 78.4766 5.313 76.6286 5.313C74.7596 5.313 73.4366 6.993 73.4366 9.093V9.849C73.4366 11.97 74.7386 13.629 76.6076 13.629Z" fill="#1C86E3"/>
    <path d="M87.2379 15.015C84.7809 15.015 83.1009 13.965 82.8699 12.243H84.7389C85.0119 13.125 85.9359 13.65 87.2379 13.65C88.7499 13.65 89.8419 12.936 89.8419 11.928C89.8419 11.13 89.2959 10.647 87.6999 10.311L85.9989 9.933C84.0249 9.513 83.0799 8.568 83.0799 7.056C83.0799 5.145 84.7179 3.927 87.2379 3.927C89.5689 3.927 91.1229 4.956 91.2909 6.657H89.5479C89.3169 5.817 88.4559 5.292 87.2379 5.292C85.9359 5.292 84.8229 5.838 84.8229 6.972C84.8229 7.896 85.4529 8.232 86.9229 8.568L88.5609 8.904C90.5769 9.345 91.6269 10.353 91.6269 11.802C91.6269 13.776 89.9049 15.015 87.2379 15.015Z" fill="#1C86E3"/>
  </svg>
</template>