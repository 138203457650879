<template>
  <v-row>
    <v-col class="space-y-5 w-full">
      <div class="rounded-xl bg-[#fff] p-5">
        <div class="flex items-center space-x-3 mb-5">
          <ExamsOpenIcon />
          <span class="text-B&W-20 font-medium !text-[1rem] leading-6 tracking-[0.15px]">
            Simulados em aberto
          </span>
          <v-chip color="blue" rounded size="small" variant="flat"
            >{{ applicationsNotStarted?.length }}
          </v-chip>
        </div>
        <template v-if="isLoadingOpenApplications">
          <ApplicationSkeletonLoader />
        </template>
        <template v-else-if="applicationsNotStarted?.length">
          <ApplicationsList
            :applications="applicationsNotStarted"
            :is_not_started="true"
            @resume="resumeApplication($event)"
            @start="startApplication($event)"
          />
        </template>
        <template v-else>
          <div class="flex justify-center items-center pt-[24px] flex-column">
            <div class="mb-[24px] font-bold">Você não tem simulados em aberto!</div>
            <img :src="svgPath.student_no_exams" alt="Estudante sem Simulados encontradas" />
          </div>
        </template>
      </div>
      <div class="rounded-xl bg-[#fff] p-5">
        <div class="flex items-center space-x-3 mb-5">
          <ExamsFinishedIcon />
          <span class="text-B&W-20 font-medium !text-[1rem] leading-6 tracking-[0.15px]"
            >Simulados concluídos</span
          >
          <v-chip color="blue" rounded size="small" variant="flat"
            >{{ userApplicationsFinished.length }}
          </v-chip>
        </div>
        <template v-if="isUserApplicationsLoading">
          <ApplicationSkeletonLoader />
        </template>
        <template v-else-if="paginatedApplicationsFinished?.length">
          <div>
            <ApplicationsList
              :applications="paginatedApplicationsFinished"
              :is_not_started="false"
            />
            <div class="flex justify-end mt-5 overflow-hidden">
              <v-pagination
                v-if="!isUserApplicationsLoading && totalPages > 1"
                :length="totalPages!"
                :modelValue="currentPage"
                :total-visible="mobile ? 1 : 4"
                color="primary"
                variant="outlined"
                @update:modelValue="changePage"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex justify-center items-center pt-[24px] flex-col">
            <div class="mb-[24px] font-bold">Não encontramos nenhum simulado</div>
            <img :src="svgPath.student_no_exams_done" alt="Estudante sem simulados concluídos" />
          </div>
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import ApplicationsList from './ApplicationsList.vue'
import ExamsOpenIcon from '../icons/ExamsOpenIcon.vue'
import { computed, onMounted, readonly, ref, watch } from 'vue'
import {
  getApplicationsFromDay,
  getUserApplications,
  getUserCoursesOnActiveOrganization,
  saveFormDataIntoUserApplication,
  startUserApplication
} from '@/services/application.service'
import type { Application } from '@/models/application.model'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import moment from 'moment'
import { useLoadingStore } from '@/stores/loading'
import ApplicationSkeletonLoader from '@/components/dashboard/ApplicationSkeletonLoader.vue'
import { getActiveOrganizationData } from '@/services/organization.service'
import { getUserData } from '@/services/user.service'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { useCollection } from 'vuefire'
import { db } from '@/config/firebase'
import { ApplicationStatus } from '@/enums/application-status.enum'
import ExamsFinishedIcon from '@/components/icons/ExamsFinishedIcon.vue'

const { mobile } = useDisplay()
const loadingStore = useLoadingStore()

const applicationsNotStarted = ref([] as Application[])
const applicationsFromDay = ref([] as Application[])
const isLoadingOpenApplications = ref(false)
const isLoadingApplicationsFromDay = ref(false)
const userData = ref({} as any)
const getProviderId = ref('')
const currentPage = ref(1)
const itemsPerPage = 4

const userApplicationsFinishedSource = computed(() => {
  if (!userData.value?.id) return

  return query(
    collection(db, 'users', userData.value.id, 'applications'),
    where('status', 'in', [
      ApplicationStatus.ENDED,
      ApplicationStatus.CORRECTED,
      ApplicationStatus.PUBLISHED,
      ApplicationStatus.WAITING,
      ApplicationStatus.CANCELLED,
      ApplicationStatus.EXPIRED
    ]),
    orderBy('ended_at', 'desc')
  )
})

const userApplicationsFinished = useCollection(userApplicationsFinishedSource)

const isUserApplicationsLoading = computed(() => userApplicationsFinished?.pending?.value)

const paginatedApplicationsFinished = computed(() => {
  if (!userApplicationsFinished.value?.length) return []
  const startIndex = (currentPage.value - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  return userApplicationsFinished.value.slice(startIndex, endIndex)
})

const totalPages = computed(() => {
  return Math.ceil((userApplicationsFinished.value?.length || 0) / itemsPerPage)
})

const changePage = (page: number) => {
  currentPage.value = page
}

onMounted(async () => {
  try {
    isLoadingOpenApplications.value = true
    isLoadingApplicationsFromDay.value = true
    ;[userData.value] = await Promise.all([
      getUserData(),
      handleOpenApplications(),
      handleApplicationsFromDay(),
      getProvider()
    ])

    const queries = await getQueries()

    queries.forEach((query) => {
      onSnapshot(query, async () => {
        await Promise.all([handleOpenApplications(), handleApplicationsFromDay()])
      })
    })
  } catch (e) {
    console.log(e)
  } finally {
    isLoadingOpenApplications.value = false
    isLoadingApplicationsFromDay.value = false
  }
})

async function getProvider() {
  const organization = await getActiveOrganizationData()
  getProviderId.value = organization?.provider_id
}

async function handleOpenApplications() {
  try {
    applicationsNotStarted.value = await getUserApplications()
  } catch (e) {
    console.log(e)
  }
}

async function handleApplicationsFromDay() {
  try {
    applicationsFromDay.value = await getApplicationsFromDay(
      moment(selectedDate.value).format('YYYY-MM-DD')
    )
  } catch (e) {
    console.log(e)
  }
}

const selectedDate = ref(new Date())

watch(selectedDate, async (newDate) => {
  applicationsFromDay.value = await getApplicationsFromDay(moment(newDate).format('YYYY-MM-DD'))
})

const selectedApplication = ref({} as Application)
const dialog = ref(false)

const startCard = (application: Application) => {
  if (application?.is_google_forms) {
    window.open(application?.google_forms?.responderUri, '_blank')
    return
  }

  dialog.value = true
  selectedApplication.value = application
}

const router = useRouter()

const startApplication = async (application: Application) => {
  try {
    loadingStore.startLoading()
    const userApplicationId = await saveFormDataIntoUserApplication(application)
    await startUserApplication(userApplicationId)
    await router.push(`/resolution/${userApplicationId}`)
  } catch (e) {
    console.log(e)
  } finally {
    loadingStore.stopLoading()
  }
}

const resumeApplication = async (application: Application) => {
  await router.push(`/resolution/${application.id}`)
}

watch(dialog, (newDialog) => {
  if (!newDialog) {
    selectedApplication.value = {} as Application
  }
})

const svgPath = ref(
  readonly({
    student_no_exams: '/assets/svg/student_no_exams.svg',
    student_no_exams_done: '/assets/svg/student_confused.svg'
  })
)

async function getQueries() {
  const user = await getUserData()

  const { coursesId, schoolsId } = await getUserCoursesOnActiveOrganization()

  const organizationApplicationRef = collection(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string,
    'applications'
  )

  const organizationApplicationQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('is_apply_to_organization', '==', true)
  )

  const schoolsApplicationsQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('schools_id', 'array-contains-any', schoolsId),
    where('is_apply_to_organization', '==', false)
  )

  const coursesApplicationsQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('courses_id', 'array-contains-any', coursesId),
    where('is_apply_to_organization', '==', false)
  )

  const usersApplicationQuery = query(
    organizationApplicationRef,
    where('status', '==', ApplicationStatus.RUNNING),
    where('users_id', 'array-contains-any', [user.id]),
    where('is_apply_to_organization', '==', false)
  )

  const userApplicationsQuery = query(
    collection(user.ref, 'applications'),
    where('organization_id', '==', localStorage.getItem('activeOrganization') as string)
  )

  return [
    organizationApplicationQuery,
    schoolsApplicationsQuery,
    coursesApplicationsQuery,
    usersApplicationQuery,
    userApplicationsQuery
  ]
}
</script>
