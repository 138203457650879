<template>
  <template v-if="!loadingFile">
    <DropZone ref="dropZone" #default="{ dropZoneActive }" class="w-full" @onDrop="onSelectFile">
      <div v-if="hasError" class="flex flex-col items-center space-y-5 bg-[#fff] rounded-lg p-3">
        <span class="text-ellipsis text-[#4A545E] text-sm font-normal"
          >Não foi possível ler o documento. A foto deve ser clara, sem borrões e a letra legível.
        </span>
        <button
          class="flex items-center justify-center border-solid border-[1px] space-x-2 border-azul-50 rounded text-sm h-full p-3"
          @click="resetFileAfterError"
        >
          <span class="text-ellipsis text-[#4A545E] text-sm font-normal"> Tente novamente </span>
        </button>
      </div>
      <div
        v-else
        :class="dropZoneActive ? 'bg-azul-cinza' : ''"
        class="flex border-dashed border-spacing-x-10 border-[2px] border-[#1c86e3] min-h-[8rem] bg-[#fff] mt-5 flex-col justify-center items-center gap-2.5 self-stretch rounded-lg cursor-pointer group"
        @click="file?.click()"
      >
        <div
          :class="dropZoneActive && 'animate-bounce'"
          class="flex flex-col md:flex-row justify-center items-center gap-2"
        >
          <div class="rounded bg-[#1C86E3] shadow-sm">
            <div class="group-hover:scale-75 scale-transition duration-500">
              <ImageFileIcon />
            </div>
          </div>
          <div class="flex flex-col">
            <span class="text-[#333333] text-xs md:text-sm font-normal text-center">
              {{ dropZoneActive ? 'Solte o arquivo aqui' : 'Clique aqui para enviar sua redação' }}
            </span>
            <span class="text-[#808080] hidden md:block text-xs font-normal">
              Permitido apenas PNG, JPG ou JPEG
            </span>
          </div>
          <input
            ref="file"
            style="display: none"
            type="file"
            @change="handleFileChange"
            @click="handleFileClick"
          />
        </div>
      </div>
    </DropZone>
  </template>
  <template v-else>
    <div
      class="flex border-dashed border-spacing-x-10 border-[2px] border-[#1c86e3] min-h-[8rem] bg-[#fff] mt-5 flex-col justify-center items-center gap-2.5 self-stretch rounded-lg cursor-pointer group"
    >
      <span class="text-[#808080] text-xs">Não feche o aplicativo</span>
    </div>
  </template>
</template>

<script lang="ts" setup>
import DropZone from './DropZone.vue'
import { ref } from 'vue'
import StorageService from '@/services/storage.service'
import { v4 as uuidv4 } from 'uuid'
import { model } from '@/config/firebase'
import { useSnackbarStore } from '@/stores/snakebar'
import ImageEnum from '@/enums/image.enum'
import ImageFileIcon from '@/components/icons/ImageFileIcon.vue'
import { useNavigator } from '@/composables/useNavigation'
import OperationSystemEnum from '@/enums/operation.system.enum'

const storageService = new StorageService()

const snackbarStore = useSnackbarStore()

const file = ref()
const loadingFile = ref<boolean>(false)
const hasError = ref(false)
const transcribedText = ref()

const emit = defineEmits<{
  (event: 'onDrop', file: File): void
  (event: 'essayResponse', response: any): void
}>()

const props = defineProps({
  reset: {
    type: Function,
    required: false
  }
})

const resetFileAfterError = () => {
  props.reset?.()
  hasError.value = false
}

const validateTypeFile = (file: File) => {
  if (![ImageEnum.JPG, ImageEnum.PNG, ImageEnum.JPEG].includes(file?.type as ImageEnum)) {
    snackbarStore.showSnackbar(['Apenas arquivos JPG, JPEG e PNG são permitidos'], 'error')
    return false
  }

  return true
}

const handleFileChange = (event: Event) => {
  const input = event.target as HTMLInputElement
  const files = input.files as FileList

  if (files?.length > 0) {
    onSelectFile(files)
  }
}

const handleFileClick = async (event: MouseEvent) => {
  const input = event.target as HTMLInputElement
  input.value = ''
  await openSelectFile()
}

const onSelectFile = async (files: FileList) => {
  try {
    loadingFile.value = true
    const file = files?.[0]

    if (files?.length > 1) {
      snackbarStore.showSnackbar(['Apenas um arquivo é permitido'], 'error')
      return
    }

    if (!validateTypeFile(file)) {
      return
    }

  } catch (e) {
    console.error(e)
    hasError.value = true
    snackbarStore.showSnackbar(['Tente novamente'], 'error')
  } finally {
    loadingFile.value = false
  }
}

const processFile = async (file: File, uuid: string) => {
  try {
    loadingFile.value = true
    const snapshot = await storageService.uploadFile(`files/${uuid}_${file.name}`, file)
    const { response: transcriberResult } = await model.generateContent([
      'Transcribe the text in the image',
      {
        fileData: {
          mimeType: snapshot.metadata.contentType as ImageEnum,
          fileUri: `gs://${import.meta.env.VITE_STORAGE_BUCKET}/files/${snapshot?.metadata?.name}`
        }
      }
    ])

    transcribedText.value = transcriberResult.text()
    emit('essayResponse', transcribedText.value)
  } catch (e) {
    console.error(e)
    hasError.value = true
    snackbarStore.showSnackbar(['Erro ao processar o arquivo. Tente novamente.'], 'error')
  } finally {
    loadingFile.value = false
  }
}

function postMessage(message: any) {
  try {
    // @ts-ignore
    window.FlutterChannel.postMessage(JSON.stringify(message))
  } catch {
    // Do nothing
  }
}

function openSelectFile() {
  postMessage({
    type: 'selectFile'
  })

  window.removeEventListener('fileEvent', cameraEvent)
  window.addEventListener('fileEvent', cameraEvent)

  async function cameraEvent(e: any) {
    try {
      loadingFile.value = true
      const detail = (e as CustomEvent).detail
      const { file } = JSON.parse(detail)
      const base64Data = file.replace(/^data:.+;base64,/, '')
      const byteCharacters = atob(base64Data)
      const byteNumbers = new Array(byteCharacters.length)

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'image/jpeg' }) // Adjust the MIME type as needed
      const fileName = `image_${Date.now()}.jpg` // Generate a unique file name
      const fileObject = new File([blob], fileName, { type: 'image/jpeg' })

      await processFile(fileObject, uuidv4())
    } catch (e) {
      console.error(e)
      hasError.value = true
      snackbarStore.showSnackbar(['Erro ao processar o arquivo. Tente novamente.'], 'error')
    } finally {
      loadingFile.value = false
      window.removeEventListener('fileEvent', cameraEvent)
    }
  }

}
</script>
