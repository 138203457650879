<template>
  <div id="mainToolbarParent">
    <div class="flex justify-center bg-[#fff] h-[5rem] rounded-b-xl px-5 py-5 z-40 mb-5" id="mainToolbar">
      <div class="flex items-center justify-center">
        <EnemSimuladoIcon />
      </div>
      <div class="flex justify-end items-center">
        <div class="flex justify-end">
          <v-btn
            :class="{
              '!border-2 text-none': !mobile,
              '!border-2 text-none ml-3': mobile
            }"
            color="blue"
            variant="outlined"
            @click="router.back()"
          >
            <span :style="fontSm">Voltar</span>
          </v-btn>
          <v-btn
            :class="{
              '!border-2 text-none': !mobile,
              '!border-2 text-none ml-3': mobile
            }"
            color="blue"
            variant="outlined"
            @click="btnAction"
          >
            <span :style="fontSm">{{
                !mobile ? (isShowResolution ? 'Revisar e finalizar' : 'Finalizar') : 'Finalizar'
              }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { db } from '@/config/firebase'
import { computed, inject, onMounted, type Ref, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { endApplicationAndSave, getApplicationQuestions } from '@/services/application.service'
import moment from 'moment'
import { useFontZoomStore } from '@/stores/font'
import { useTimerCounterStore } from '@/stores/counter'
import { getBrazilianDate } from '@/utils/date'
import { useDisplay } from 'vuetify'
import { doc, onSnapshot } from 'firebase/firestore'
import { getActiveOrganizationData } from '@/services/organization.service'
import { getProviderById } from '@/services/provider.service'
import { useAuth } from '@/composables/useAuth'
import { useConfettiStore } from '@/stores/confetti'
import { useSnackbarStore } from '@/stores/snakebar'
import { useLoadingStore } from '@/stores/loading'
import EnemSimuladoIcon from '@/components/icons/EnemSimuladoIcon.vue'

const isShowResolution = inject('isShowResolution') as Ref
const isPruvo = ref(false)
const providerLogo = ref<string>('')
const { user } = useAuth()
const { mobile } = useDisplay()

const props = defineProps({
  showTimer: {
    type: Boolean,
    default: true
  }
})

const { showTimer } = toRefs(props)

const btnAction = async () => {
  if (isShowResolution.value) {
    isShowResolution.value = false
    return
  }

  await endApplication()
}

const route = useRoute()
const router = useRouter()
const fontZoomStore = useFontZoomStore()
const confettiStore = useConfettiStore()
const snackbarStore = useSnackbarStore()
const loadingStore = useLoadingStore()

const timer: any = ref('')
let timerId: any = null
const applicationData = ref({} as any)

const fontSize = computed(() => fontZoomStore.getFontZoom)
const fontSm = computed(() => useFontZoomStore().getFontSm)

onMounted(async () => {
  clearInterval(timerId)

  const data = (await getApplicationQuestions(route.params.applicationId as string)) as any

  if (!data) {
    return router.push({ name: 'dashboard' })
  }

  applicationData.value = data.application

  onSnapshot(
    doc(db, 'users', data.userApplication.user_id, 'applications', data.userApplication.id),
    (doc) => {
      data.userApplication = doc.data()

      const { duration, started_at } = data.userApplication
      const finishesAt = getBrazilianDate(moment.unix(started_at?.seconds)).add(duration, 'minutes')

      clearInterval(timerId)

      timerId = setInterval(async () => {
        const diff = moment.duration(finishesAt.diff(moment()))

        if (duration) {
          timer.value = `${formatTime(diff.hours())}:${formatTime(diff.minutes())}:${formatTime(
            diff?.seconds()
          )}`
        } else {
          timer.value = null
        }
      }, 1000)
    }
  )

  await getProviderLogo()
})

const formatTime = (time: number) => {
  return time.toString().padStart(2, '0')
}

const endApplication = async () => {
  try {
    loadingStore.startLoading()
    clearInterval(timerId)
    useTimerCounterStore().stopCounter()
    const timeApplicationSpent = useTimerCounterStore().getCounter
    await endApplicationAndSave(
      route.params.applicationId as string,
      applicationData.value.id,
      timeApplicationSpent
    )
    await router.push({ name: 'dashboard' })
    confettiStore.startConfetti()
    setTimeout(() => confettiStore.stopConfetti(), 3000)
  } catch (e) {
    console.log(e)
    snackbarStore.showSnackbar(
      ['Ah não! Não conseguimos terminar a avaliação. Que tal tentar mais uma vez?'],
      'error'
    )
  } finally {
    loadingStore.stopLoading()
  }
}

const getProviderLogo = async () => {
  const organizationData = await getActiveOrganizationData()
  const providerData = await getProviderById(organizationData?.provider_id)

  if (organizationData?.provider_id === 'PRUVO') {
    isPruvo.value = true
    return
  }

  providerLogo.value = providerData?.logo
}

const handleScroll = (
  elementId: string,
  parentElementId: string,
  offset: number,
  additionalClass: string = ''
) => {
  const element = document.getElementById(elementId) as HTMLElement
  const parentElement = document.getElementById(parentElementId) as HTMLElement

  if (!element) return

  if (window.scrollY > offset) {
    element.classList.add('position-fixed')
    if (additionalClass) element.classList.add(additionalClass)
    element.style.width = `${parentElement.offsetWidth}px`
    return
  }

  element.classList.remove('position-fixed')
  if (additionalClass) element.classList.remove(additionalClass)
  element.style.width = ''
}

window.addEventListener('scroll', () => handleScroll('mainToolbar', 'mainToolbarParent', 60))

</script>