<template>
  <AppBarImpersonate />
  <Suspense>
    <v-layout>
      <v-container class="px-5 pa-0" fluid>
          <LoggedHeader v-if="showLoggedHeader" />
          <ResolutionHeader v-else style="z-index: 9999" />
          <slot name="body" />
      </v-container>
    </v-layout>
  </Suspense>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue'
import LoggedHeader from '../dashboard/LoggedHeader.vue'
import ResolutionHeader from '../resolution/ResolutionHeader.vue'
import AppBarImpersonate from './AppBarImpersonate.vue'

const props = defineProps({
  showLoggedHeader: {
    type: Boolean,
    default: true
  }
})

const { showLoggedHeader } = toRefs(props)
</script>

<style scoped>
.w-92 {
  max-width: 92%;
}

.shadow-sheet {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.02);
}
</style>
